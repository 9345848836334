import React from "react";
import Navbar from "./Navbar";
import Content from "./Content";

type Props = {
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Header = ({ setMenuOpen }: Props) => {
  return (
    <div className="header__container">
      <Navbar setMenuOpen={setMenuOpen} />
      <Content />
    </div>
  );
};

export default Header;
