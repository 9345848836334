import React from "react";
import { ArrowRight } from "../icons/iconList";

type Props = {};

const Content = (props: Props) => {
  return (
    <div className="content__container">
      <div className="content__box">
        <h1>
          <span>EXPLOREZ</span>
          <span>EN FEDERATION</span>
          <span>DE RUSSIE</span>
        </h1>
        <p>
          ETUDIER DANS N’IMPORTE QUELLE FILIERE DE VOTRE CHOIX DANS N’IMPORTE
          QUELLE UNIVERSITE EN RUSSIE
        </p>
        <button>
          <div>
            <ArrowRight />
          </div>
          <span>DECOUVRIR</span>
        </button>
      </div>
    </div>
  );
};

export default Content;
