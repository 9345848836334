import React from "react";

type Props = {};

const subtitles = [
  "1. Conseils Personnalisés",
  "2. Inscription",
  "3. Assistance de l'etudiant",
];

const slides = [
  {
    id: 1,
    picture: "/assets/header.png",
    desc: "Notre engagement envers votre réussite académique commence par des conseils personnalisés. Nous travaillons en étroite collaboration avec chaque étudiant pour les aider à choisir le programme d'études qui correspond à leurs aspirations académiques et professionnelles. Comprendre vos objectifs est essentiel pour déterminer le niveau d'études, la spécialisation, et l'université qui vous conviendront le mieux.",
  },
];

const Service = (props: Props) => {
  return (
    <div className="service__container">
      <div className="service__wrapper">
        <div className="service__titles">
          <h2>Nos Services</h2>
          <div className="service__titles--links">
            {subtitles.map((el) => {
              return <h3>{el}</h3>;
            })}
          </div>
        </div>
        <div className="service__slides">
          <img src={slides[0].picture} alt="" />
          <p>{slides[0].desc}</p>
        </div>
      </div>
    </div>
  );
};

export default Service;
