import React from "react";

type Props = {};

const cards = [
  {
    id: 1,
    titre: "Notre Mission",
    desc: "Rendre chaque voyage mémorable et sans stress en offrant des services sur mesure, une expertise locale et un accompagnement de qualité.Nous nous engageons à aider nos clients à atteindre leurs objectifs qu’ils voyagent pour le loisir, les études, ou les affaires.",
  },
  {
    id: 2,
    titre: "Présence Internationale",
    desc: "Avec des bases opérationnelles en Russie, en Belgique, aux États-Unis et au Canada, Olka Service offre une couverture globale tout en restant accessible localement. Cette implantation stratégique nous permet de comprendre les spécificités culturelles et logistiques propres à chaque région.",
  },
  {
    id: 3,
    titre: "Expertise Inégalée",
    desc: "Depuis plus d’une décennie, nous accompagnons des milliers de voyageurs dans la réalisation de leurs rêves. Nos collaborateurs, passionnés de voyages, s’appuient sur un réseau de partenaires de confiance pour garantir la réussite de chaque séjour.",
  },
];

const About = (props: Props) => {
  return (
    <div className="about__container">
      <div className="about__wrapper">
        <h2>A PROPOS DE NOUS</h2>
        <div className="about__box">
          <div className="about__cards">
            {cards.map((el) => {
              return (
                <div className="about__cards--card">
                  <div className="about__cards--card__box">
                    <div className="content">
                      <h3>{el.titre}</h3>
                      <p>{el.desc}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="about__team">
            <div className="about__team--box">
              <div className="about__team--box__content">
                <h3>Service client Dédié</h3>
                <p>
                  Notre équipe multilingue est disponible 24/7 pour répondre à
                  vos questions, résoudre vos préoccupations, et ajuster vos
                  plans si nécessaire. Nous croyons que chaque voyage doit être
                  une expérience fluide et inoubliable. Tous nos partenaires,
                  des hôteliers aux agences de transport, sont rigoureusement
                  sélectionnés selon des critères de qualité et de fiabilité.
                  Votre sécurité est notre priorité : nous restons informés des
                  conditions locales et vous fournissons des conseils pour
                  voyager sereinement.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
