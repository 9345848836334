import React, { useState } from "react";
import { FaInstagram, FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import { FcBusinessman, FcBusinesswoman } from "react-icons/fc";
import { RiSendPlane2Line } from "react-icons/ri";
import Contact from "./Contact";

export type IGender = "man" | "woman" | null;
export type ICountry = "russie" | "canada" | "usa" | null;
export type IStep = "one" | "two" | "three" | "four";

type Props = {};

const Footer = (props: Props) => {
  const [gender, setGender] = useState<IGender>(null);
  const [country, setCountry] = useState<ICountry>(null);
  const [username, setUsername] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [step, setStep] = useState<IStep>("one");

  return (
    <div className="footer__container">
      <div className="footer__wrapper">
        <div className="footer__left">
          <div className="footer__logo">OLKA'S</div>
          <p>
            {`Votre partenaire de confiance pour des voyages d’études et d’affaires en Europe et en Amérique. Avec une expertise locale, un réseau mondial, et un accompagnement personnalisé, nous transformons chaque expérience en un souvenir inoubliable. Faites de vos ambitions une réalité avec Olka Service`}
          </p>
          <div className="footer__left--mycontact">
            <Contact
              number={number}
              setCountry={setCountry}
              setGender={setGender}
              setNumber={setNumber}
              setStep={setStep}
              setUsername={setUsername}
              step={step}
              username={username}
            />
          </div>
          <div className="footer__left--content">
            <div className="footer__left--content__links">
              <span>Liens</span>
              <a href="">ACCUEIL</a>
              <a href="">NOS SERVICES</a>
              <a href="">A PROPOS</a>
              <a href="">EXPLORER</a>
              <a href="">CONTACT</a>
            </div>
            <div className="footer__left--content__links">
              <span>Documents</span>
              <a href="">Mentions legals</a>
              <a href="">Politique de confitiendalité</a>
            </div>
          </div>
          <div className="footer__left--contact">
            <div className="footer__left--contact__address">
              <span>RDC, KINSHASA 201</span>
            </div>
            <div className="footer__left--contact__sociaux">
              <a href="">
                <FaWhatsapp />
              </a>
              <a href="">
                <FaInstagram />
              </a>
              <a href="">
                <FaTelegramPlane />
              </a>
            </div>
          </div>
          <div className="footer__copyright">
            <span>Copyright © 2024</span>
          </div>
        </div>
        <div className="footer__right">
          <Contact
            number={number}
            setCountry={setCountry}
            setGender={setGender}
            setNumber={setNumber}
            setStep={setStep}
            setUsername={setUsername}
            step={step}
            username={username}
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
