import React from "react";
import { AiOutlineClose } from "react-icons/ai";

type Props = {
  menuOpen: boolean;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Menu = ({ menuOpen, setMenuOpen }: Props) => {
  return (
    <div className={menuOpen ? `menu__container open` : `menu__container`}>
      <div className="menu__wrapper">
        <div className="menu__box">
          <button onClick={() => setMenuOpen(false)}>
            <AiOutlineClose />
          </button>
          <div className="menu__links">
            <a href="">ACCUEIL</a>
            <a href="">NOS SERVICES</a>
            <a href="">A PROPOS</a>
            <a href="">EXPLORER</a>
            <a href="">CONTACTS</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
