import React, { useState } from "react";
import Header from "./components/header/Header";
import Service from "./components/service/Service";
import Menu from "./components/header/Menu";
import About from "./components/about/About";
import Visit from "./components/visit/Visit";
import Footer from "./components/footer/Footer";

type Props = {};

const App = (props: Props) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  return (
    <div className="main__container">
      <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Header setMenuOpen={setMenuOpen} />
      <Service />
      <About />
      <Visit />
      <Footer />
    </div>
  );
};

export default App;
