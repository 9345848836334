import React from "react";
import { Logo, Menu } from "../icons/iconList";

type Props = {
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Navbar = ({ setMenuOpen }: Props) => {
  return (
    <div className="navbar__container">
      <div className="navbar__wrapper">
        <div className="navbar__logo">
          <Logo />
        </div>
        <button onClick={() => setMenuOpen(true)} className="navbar__menu">
          <Menu />
        </button>
      </div>
    </div>
  );
};

export default Navbar;
