import React from "react";
import { FcBusinessman, FcBusinesswoman } from "react-icons/fc";
import { RiSendPlane2Line } from "react-icons/ri";
import { ICountry, IGender, IStep } from "./Footer";

type Props = {
  setGender: (value: React.SetStateAction<IGender>) => void;
  setStep: (value: React.SetStateAction<IStep>) => void;
  setCountry: (value: React.SetStateAction<ICountry>) => void;
  setNumber: (value: React.SetStateAction<string>) => void;
  number: string;
  username: string;
  step: IStep;
  setUsername: (value: React.SetStateAction<string>) => void;
};

const Contact = ({
  number,
  setCountry,
  setGender,
  setNumber,
  setStep,
  setUsername,
  step,
  username,
}: Props) => {
  return (
    <div className="footer__contact">
      <h2>CONTACTEZ NOUS</h2>
      <p>
        Veillez remplir le formulaire suivant pour que nous puissons mieux
        comprendre votre type de besion :
      </p>
      <div className="footer__contact--slider">
        <div className={`footer__contact--first ${step === "one" && step}`}>
          <h3>Quel est votre genre ?</h3>
          <div className={`footer__contact--first__gender`}>
            <button
              onClick={() => {
                setGender("man");
                setStep("two");
              }}
            >
              <FcBusinessman />
            </button>
            <button
              onClick={() => {
                setGender("woman");
                setStep("two");
              }}
            >
              <FcBusinesswoman />
            </button>
          </div>
        </div>
        <div className={`footer__contact--first ${step === "two" && step}`}>
          <h3>Choississez votre destination :</h3>
          <div className={`footer__contact--first__gender`}>
            <button
              onClick={() => {
                setCountry("russie");
                setStep("three");
              }}
            >
              RUSSIE
            </button>
            <button
              onClick={() => {
                setCountry("canada");
                setStep("three");
              }}
            >
              CANADA
            </button>
            <button
              onClick={() => {
                setCountry("usa");
                setStep("three");
              }}
            >
              ETAT UNIS
            </button>
          </div>
        </div>
        <div className={`footer__contact--first ${step === "three" && step}`}>
          <h3>Quel est votre nom(s) et prénom(s) :</h3>
          <div className={`footer__contact--first__gender`}>
            <div className="input">
              <input
                className={
                  username.length >= 4
                    ? "checked"
                    : username.length >= 1 && username.length < 4
                    ? "unchecked"
                    : ""
                }
                onChange={(event) => setUsername(event.target.value)}
                value={username}
                type="text"
                name=""
                placeholder="Elioth Man"
              />
              <button onClick={() => username.length >= 4 && setStep("four")}>
                <RiSendPlane2Line />
              </button>
            </div>
          </div>
        </div>
        <div className={`footer__contact--first ${step === "four" && step}`}>
          <h3>Veillez entrer votre numéro whatsapp :</h3>
          <div className="footer__contact--first__gender">
            <div className="input">
              <input
                className={
                  number.length >= 4
                    ? "checked"
                    : number.length >= 1 && number.length < 4
                    ? "unchecked"
                    : ""
                }
                onChange={(event) => setNumber(event.target.value)}
                value={number}
                type={"tel"}
                placeholder="+242 06 660 77 89"
              />
              <button onClick={() => username.length >= 4 && setStep("four")}>
                <RiSendPlane2Line />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
